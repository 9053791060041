import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { between } from 'polished';
import { FaLongArrowAltRight } from 'react-icons/fa';
import Img from 'gatsby-image';

import Page from '../components/page/page';
import SEO from '../components/seo';
import HeaderSection from '../components/header-section';
import HeaderFront from '../components/header-front';
import Navigation from '../components/navigation';
import HeaderHeading from '../components/header-heading';
import HeaderDescription from '../components/header-description';
import Container from '../components/container';
import CategorySection from '../components/category-section';
import PostList from '../components/post-list';
import Thumbnail from '../components/thumbnail';

const StyledHeaderSection = styled(HeaderSection)`
	min-height: ${between('280px', '520px')};
`;

const NotFoundPage = props => (
	<Page>
		<SEO title="404 - strona nie istnieje" />
		<StyledHeaderSection hero="/hero-blog.jpg">
			<HeaderFront>
				<Navigation />
				<Container>
					<HeaderHeading>Strona nie istnieje</HeaderHeading>
					<HeaderDescription><Link to="/">Przejdź do strony głównej</Link> lub sprawdź poniższe kategorie i oferty</HeaderDescription>
				</Container>
			</HeaderFront>
		</StyledHeaderSection>

		{props.data.allMysqlCategory.edges.map(({ node }, index) => (
			<CategorySection key={index} className="slant--top slant--bottom">
				<div className="split">
					<div className="content">
						<h2>
							<Link to={`/${node.slug}/`}>{node.label_nominative}</Link>
						</h2>
						<p>
							{node.description}
						</p>
						<Link to={`/${node.slug}/`}>
							Zobacz wszystkie oferty <FaLongArrowAltRight />
						</Link>
					</div>

					<Link to={`/${node.slug}/`} className="aside" tabIndex="-1">
						<Img fluid={props.data[`cat${node.uid}`].childImageSharp.fluid} loading="lazy" alt={node.label_nominative} />
					</Link>
				</div>

				<Container>
					<PostList>
						{props.pageContext.posts[node.slug].map(post => (
							<Thumbnail key={post.node.slug} post={post.node} />
						))}
					</PostList>
				</Container>
			</CategorySection>
		))}
	</Page>
);

export default NotFoundPage;

export const query = graphql`
	query NotFoundPage {
		allMysqlTown {
			edges {
				node {
					slug
					label_nominative
				}
			}
		}

		allMysqlCategory {
			edges {
				node {
					uid
					slug
					label_nominative
					description
				}
			}
		}

		cat1: file(relativePath: { eq: "splash-music.png" }) {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid
				}
			}
		}

		cat2: file(relativePath: { eq: "splash-decor.png" }) {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid
				}
			}
		}

		cat3: file(relativePath: { eq: "splash-fashion-beauty.png" }) {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid
				}
			}
		}

		cat4: file(relativePath: { eq: "splash-foto-video.png" }) {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid
				}
			}
		}

		cat5: file(relativePath: { eq: "splash-gastronomy.png" }) {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid
				}
			}
		}

		cat6: file(relativePath: { eq: "splash-cars.png" }) {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid
				}
			}
		}

		cat7: file(relativePath: { eq: "splash-wedding-venues.png" }) {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid
				}
			}
		}

		cat8: file(relativePath: { eq: "splash-miscellaneous.png" }) {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
